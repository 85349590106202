const baseUrl = () => {
  // if (
  //   window.location.hostname === "localhost" ||
  //   window.location.hostname === "127.0.0.1"
  // ) {
  // return "http://goreps.com/ordercrm_new/api/online_ordering/";
  // return "https://www.orderonthego.ca/restaurant_login/api/online_ordering/";
  //   return "https://www.orderonthego.ca/restaurant_login/api/online_ordering/";
  // } else {
  //   return "https://www.orderonthego.ca/restaurant_login/api/online_ordering/";
  // }
  // todo: change this for each environment
  // return "https://www.orderonthego.ca/restaurant_login/api/online_ordering/";
  return "https://phpvrestaurant.orderonthego.com/api/online_ordering/";
  // return "https://qa.orderonthego.ca/restaurant_login/api/online_ordering/";
 
};

export default baseUrl;
