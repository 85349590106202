import React, { Component } from "react";
import { connect } from "react-redux";
import Modifiers from "../Modifiers/Modifiers";
import OrderActions, { OrderSelectors } from "../../Redux/OrderRedux";
import { MenuSelectors } from "../../Redux/MenuRedux";
import {
  FormControlLabel,
  TextField,
  Button,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Radio,
  Chip,
  Grid,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import StickyBox from "react-sticky-box";
import styles from "./style.module.scss";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";

const classes = (theme) => {
  return {
    quantityIcon: {
      backgroundColor: theme.palette.secondary.main,
      cursor: "pointer !important",
      color: "white !important",
      padding: "15px !important",
      marginLeft: "15px !important",
      marginRight: "15px !important",
      paddingTop: "20px !important",
      opacity: 0.8,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        opacity: 1,
      },
      "&:focus": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  };
};

class CustomizeItem extends Component {
  constructor(props) {
    super(props);

    let variationList = this.itemVariationList();
    let selectedIndex = variationList.findIndex(
      (variation) => variation.available_qty > 0
    );

    let selectedVariation =
      this.props.item.is_inventory === 1
        ? selectedIndex !== -1
          ? variationList[selectedIndex]
          : {}
        : variationList.length
        ? variationList[1]
        : {};
    let modifiersInfo = this.getFinalModifiers(selectedVariation);
    let previouslySelectedModifiers = [];
    let modifierSelectionState = modifiersInfo.selectionRequiredState;
    let quantity = 1;

    // let updatedAvailableQty = 0;
    let specialInstructions = "";
    let specialInstructionError = false;

    if (this.props.edit) {
      let existingItemJson = this.props.historyJsonItem
        ? this.props.historyJsonItem.existingJson
        : this.props.selectedProducts[this.props.editIndex];
      if (variationList.length) {
        selectedVariation = variationList.find(
          (variation) => variation.variation_id == existingItemJson.variation_id
        );
      }
      modifiersInfo = this.getFinalModifiers(selectedVariation);
      modifierSelectionState = modifiersInfo.selectionRequiredState;
      quantity = existingItemJson.quantity;
     
      specialInstructions = existingItemJson.special_instructions;
      let modifierObjWithInfo = [];
      existingItemJson.modifiers.forEach((modifier) => {
        modifiersInfo.modifiers.forEach((modObj) => {
          modifierObjWithInfo = modObj.modifiers.find(
            (mod) => mod.modifier_id == modifier.modifier_id
          );
          if (modifierObjWithInfo) {
            previouslySelectedModifiers.push(modifierObjWithInfo);
            let selectionStateObj = modifierSelectionState.find(
              (selectionStateObj) =>
                selectionStateObj.groupId ==
                modifierObjWithInfo.modifier_group_id
            );
            if (selectionStateObj) selectionStateObj.selectionComplete = true;
          }
        });
      });
    }

    modifiersInfo.modifiers.forEach((modifier) => {
      this[`${modifier.groupId}_ref`] = React.createRef();
    });

    this.state = {
      selectedVariation: selectedVariation,
      selectedModifiers: previouslySelectedModifiers.length
        ? previouslySelectedModifiers
        : [],
      variationList: variationList,
      modifiers: modifiersInfo.modifiers,
      modifierSelectionState: modifierSelectionState,
      quantity: quantity,
      specialInstructions: specialInstructions,
      previouslySelectedModifiers: previouslySelectedModifiers,
      loading: false,
      specialInstructionError: false,
      errorMessage: "",
      updatedAvailableQty: 0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if selectedVariation.variation_name has changed
    if (
      this.state.selectedVariation.variation_name !==
      prevState.selectedVariation.variation_name
    ) {
      // Reset quantity to initial value (e.g., 1)
      this.setState({ quantity: 1, errorMessage: "" });
    }

    // if (
    //   this.state.selectedVariation.available_qty !==
    //   prevState.selectedVariation.available_qty
    // ) {
    //   this.setState({ selectedVariation: this.state.selectedVariation });
    // }

    // if (
    //   prevProps.item !== this.props.item ||
    //   prevProps.historyJsonItem !== this.props.historyJsonItem
    // ) {
    //   this.updateAvailableQty();
    // }
  }

  // updateAvailableQty() {
  //   let newQty = 7; // Default value

  //   if (
  //     this.props.item.is_inventory === 1 &&
  //     this.props.item.stop_sales_negative_stock === true &&
  //     this.props.item.has_variation === true
  //   ) {
  //     // Your condition to determine the new quantity
  //     newQty = this.props.item.available_qty; // Replace with your actual logic
  //   }

  //   this.setState({ updatedAvailableQty: newQty });
  // }

  itemVariationList = () => {
    let variationListWithNames = [];
    let itemVariationList = this.props.historyJsonItem
      ? this.props.historyJsonItem.item.item_variation_list
      : this.props.item.item_variation_list;

    let allVariationList = this.props.historyJsonItem
      ? this.props.historyJsonItem.variationList
      : this.props.variationList;

    itemVariationList.forEach((variation) => {
      let name = allVariationList.find(
        (variationWithName) =>
          variationWithName.variation_id == variation.variation_id
      );

      let nameWithCostAndQty;

      let qty = variation.available_qty;

      const checkoutQtyItem = this.props.selectedProductsQty.find(
        (item) => item.variation_id === variation.variation_id
      );

      if (checkoutQtyItem) {
        qty -= checkoutQtyItem.total_quantity;
      }

      qty = Math.max(qty, 0);

      if (
        this.props.item.is_inventory === 1 &&
        this.props.item.stop_sales_negative_stock === true &&
        this.props.item.has_variation === true
      ) {
        nameWithCostAndQty = Object.assign(
          { variation_cost: variation.variation_cost },
          name,

          { available_qty: qty }
        );
        variationListWithNames.push(nameWithCostAndQty);
      } else {
        let nameWithCost = Object.assign(
          { variation_cost: variation.variation_cost },
          name
        );
        variationListWithNames.push(nameWithCost);
      }
    });

    return variationListWithNames;
  };

  isSelectedVariation = (variation) => {
    if (this.state.selectedVariation) {
      return (
        this.state.selectedVariation.variation_name == variation.variation_name
      );
    } else {
      return (
        variation.variation_name == this.state.variationList[0].variation_name
      );
    }
  };

  setSelectedVariation = (variation) => {
    let modifiersInfo = this.getFinalModifiers(variation);
    this.setState((prevState) => ({
      selectedVariation: variation,
      selectedModifiers: [],
      modifiers: modifiersInfo.modifiers,
      modifierSelectionState: modifiersInfo.selectionRequiredState,
      previouslySelectedModifiers: [],
    }));
  };

  addModifier = (modifierInfo) => {
    let newSelectedModifiers = [];
    if (this.state.selectedModifiers.length > 0) {
      newSelectedModifiers = [...this.state.selectedModifiers, modifierInfo];
    } else {
      newSelectedModifiers.push(modifierInfo);
    }
    this.setState((prevState) => ({ selectedModifiers: newSelectedModifiers }));
  };

  removeModifier = (modifierInfo) => {
    let index = this.state.selectedModifiers.findIndex(
      (modifier) => modifier.modifier_id == modifierInfo.modifier_id
    );
    let prevModifiers = this.state.selectedModifiers;
    prevModifiers.splice(index, 1);
    this.setState((prevState) => ({ selectedModifiers: prevModifiers }));
  };

  sortSelectedModifiers = () => {
    let sortedModifiers = [];

    this.state.modifiers.forEach((modifierGroup) => {
      modifierGroup.modifiers.forEach((modifier) => {
        let selectedMod = this.state.selectedModifiers.filter(
          (selectedModifier) =>
            selectedModifier.modifier_id == modifier.modifier_id
        );
        selectedMod.forEach((mod) => {
          sortedModifiers.push(mod);
        });
      });
    });
    return sortedModifiers;
  };

  addToOrder = () => {
    if (
      !this.isModifierSelectionStateComplete() ||
      this.state.specialInstructionError
    ) {
      let flag = false;
      let groupId = null;
      this.state.modifiers.map((modifierObj) => {
        this.state.modifierSelectionState.map((selectionObj) => {
          if (
            selectionObj.groupId == modifierObj.groupId &&
            !selectionObj.selectionComplete &&
            !flag
          ) {
            groupId = modifierObj.groupId;
            flag = true;
          }
        });
      });

      let wrapper = document.getElementById("modifiers");

      let count = 0;
      if (window.innerWidth < 962) {
        count =
          this[`${groupId}_ref`].current.getBoundingClientRect().top - 180;
      } else {
        count =
          this[`${groupId}_ref`].current.getBoundingClientRect().top - 240;
      }

      wrapper.scrollBy({ top: count, left: 0, behavior: "smooth" });
    } else {
      this.setState({ loading: true });
      let sortedModifiers = this.sortSelectedModifiers();
      if (this.props.edit) {
        if (this.props.historyJsonItem) {
        } else {
          this.props.dispatch(OrderActions.removeProduct(this.props.editIndex));
          this.props.dispatch(
            OrderActions.addProduct(
              this.props.item,
              this.state.selectedVariation,
              sortedModifiers,
              this.props.taxList,
              this.state.quantity,
              this.state.specialInstructions
            )
          );
          this.props.closeItemEdit();
          this.setState({ loading: false });
        }
      } else {
        this.props.productAddedNotifier();

        this.props.dispatch(
          OrderActions.addProduct(
            this.props.item,
            this.state.selectedVariation,
            sortedModifiers,
            this.props.taxList,
            this.state.quantity,
            this.state.specialInstructions
          )
        );

        this.props.closeItemSelection();

        this.setState({
          loading: false,
        });
      }
    }
  };

  displayModifierList = () => {
    return this.state.modifiers.map((modifierObj) => {
      return (
        <div ref={this[`${modifierObj.groupId}_ref`]}>
          <Modifiers
            modifierObj={modifierObj}
            addModifier={this.addModifier}
            key={
              modifierObj.groupId + this.state.selectedVariation.variation_id
            }
            removeModifier={this.removeModifier}
            getModifierSelectedCount={this.getModifierSelectedCount}
            setModifierSelectionState={this.setModifierSelectionState}
            previouslySelectedModifiers={this.state.previouslySelectedModifiers}
          />
        </div>
      );
    });
  };

  getModifierSelectedCount = (modifierInfo) => {
    return this.state.selectedModifiers.filter(
      (modifier) => modifier.modifier_id == modifierInfo.modifier_id
    ).length;
  };

  setModifierSelectionState = (group_id, status) => {
    let index = this.state.modifierSelectionState.findIndex(
      (modifier) => modifier.groupId == group_id
    );
    if (index > -1) {
      let prevState = this.state.modifierSelectionState;
      prevState[index].selectionComplete = status;
      this.setState({ modifierSelectionState: prevState });
    }
  };

  isModifierSelectionStateComplete = () => {
    let status = true;
    this.state.modifierSelectionState.forEach((modState) => {
      if (modState && !modState.selectionComplete) status = false;
    });
    return status;
  };

  getOrderButtonText = () => {
    return this.isModifierSelectionStateComplete()
      ? (this.props.edit ? "Update Item " : "Add to Order ") +
          "$" +
          this.getCurrentOrderCost().toFixed(2)
      : "Selection Required";
  };

  isVariationLinked = (variations_linked, selectedVariation) => {
    let variationLinked = false;
    variations_linked.forEach((variation) => {
      if (variation.variation_id == selectedVariation.variation_id) {
        variationLinked = true;
      }
    });
    return variationLinked;
  };

  getGroupNameFromId = (group_id) => {
    let groupList = this.props.historyJsonItem
      ? this.props.historyJsonItem.groupList
      : this.props.groupList;
    let groupObj = groupList.find((group) => group.group_id == group_id);
    return groupObj.group_name;
  };

  getFinalModifiers = (selectedVariation) => {
    let finalModifiers = { modifiers: [], selectionRequiredState: [] };
    let itemModifierList = this.props.historyJsonItem
      ? this.props.historyJsonItem.item.item_modifier_list
      : this.props.item.item_modifier_list;
    let allModifierList = this.props.historyJsonItem
      ? this.props.historyJsonItem.modifierList
      : this.props.modifierList;
    itemModifierList.forEach((modifiersObj) => {
      let validModifiers = [];
      if (modifiersObj.variation_linked[0]) {
        if (
          this.isVariationLinked(
            modifiersObj.variation_linked,
            selectedVariation
          )
        ) {
          modifiersObj.allow_modifier_list.map((modifier) => {
            let modifierInfo = allModifierList.find(
              (modifierInfoObj) =>
                modifierInfoObj.modifier_id == modifier.modifier_id
            );
            validModifiers.push(modifierInfo);
          });
        }
      } else {
        modifiersObj.allow_modifier_list.map((modifier) => {
          let modifierInfo = allModifierList.find(
            (modifierInfoObj) =>
              modifierInfoObj.modifier_id == modifier.modifier_id
          );
          validModifiers.push(modifierInfo);
        });
      }
      if (validModifiers.length > 0) {
        finalModifiers.modifiers.push({
          modifiers: validModifiers,
          groupName: this.getGroupNameFromId(modifiersObj.group_id),
          required: modifiersObj.is_required,
          minimumCount: modifiersObj.minimum_count,
          maximumCount: modifiersObj.maximum_count,
          groupId: modifiersObj.group_id,
        });
        if (
          modifiersObj.is_required == 1 ||
          modifiersObj.is_required == 2 ||
          modifiersObj.is_required == 5
        ) {
          finalModifiers.selectionRequiredState.push({
            groupId: modifiersObj.group_id,
            selectionComplete: false,
          });
        }
      }
    });
    return finalModifiers;
  };

  getCurrentOrderCost = () => {
    let currentCost = this.props.historyJsonItem
      ? this.props.historyJsonItem.item.item_cost
      : this.props.item.item_cost;
    if (this.state.selectedVariation.variation_cost) {
      currentCost = this.state.selectedVariation.variation_cost;
    }
    this.state.selectedModifiers.forEach(
      (modifier) => (currentCost += modifier.modifier_cost)
    );
    return currentCost * this.state.quantity;
  };

  increaseQuantity = () => {
    this.setState((prevState) => ({ quantity: +prevState.quantity + 1 }));
  };

  decreaseQuantity = () => {
    this.setState((prevState) => ({ quantity: +prevState.quantity - 1 }));
  };

  render() {
    if (
      this.props.item.is_inventory === 1 &&
      this.props.item.stop_sales_negative_stock === true
    ) {
      const { classes } = this.props;
      let itemName = this.props.historyJsonItem
        ? this.props.historyJsonItem.item.item_name
        : this.props.item.item_name;
      let itemDescription = this.props.historyJsonItem
        ? this.props.historyJsonItem.item.description
        : this.props.item.description;

      const selectedVariation = this.state.variationList.find(
        ({ variation_name }) =>
          variation_name === this.state.selectedVariation.variation_name
      );

      let available_qty;

      if (selectedVariation && this.props.item.has_variation) {
        available_qty = selectedVariation.available_qty;
      } else {
        available_qty = this.props.item.available_qty;
      }

      const isAddToCartDisabled = available_qty <= 0 || available_qty === 0;

      return (
        <div
          style={{
            justifyContent: "center",
            backgroundColor: "#fff",
            height: "100%",
          }}
        >
          <StickyBox className={styles.titleContainer}>
            <div className={styles.titleBox}>
              <div className="font20Bold">{itemName}</div>
              <div className="subhead">{itemDescription}</div>
            </div>
          </StickyBox>
          <Divider />
          {this.state.variationList.length > 0 && (
            <div style={{ display: "block" }}>
              <div className={`font16Bold ${styles.modifierTitle}`}>
                Choose Your Size
              </div>
              <List className={styles.listContainerStyle}>
                {this.state.variationList.map((variation, i) => (
                  <ListItem
                    key={"variation" + i}
                    button
                    onClick={() => this.setSelectedVariation(variation)}
                    className={styles.listStyle}
                    disabled={
                      variation.available_qty === 0 ||
                      variation.available_qty <= 0
                    }
                  >
                    <ListItemText
                      primary={
                        variation.variation_name +
                        " ($" +
                        (+variation.variation_cost).toFixed(2) +
                        ")" +
                        "(" +
                        variation.available_qty +
                        ")"
                      }
                    />
                    <Radio
                      checked={this.isSelectedVariation(variation)}
                      className="font16"
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          )}
          <div>{this.displayModifierList()}</div>
          <Divider />
          <div className={styles.specialInstructionText}>
            <TextField
              id="standard-basic"
              label="Special Instructions"
              fullWidth={true}
              className={styles.specialInstructionTextStyle}
              backgroundColor="#f2f4f5"
              value={this.state.specialInstructions}
              onChange={(event) => {
                var regex = new RegExp("^[a-zA-Z .,_ ]+$");
                if (regex.test(event.target.value) || !event.target.value) {
                  this.setState({
                    specialInstructions: event.target.value,
                    specialInstructionError: false,
                  });
                } else {
                  this.setState({
                    specialInstructions: event.target.value,
                    specialInstructionError: true,
                  });
                }
              }}
            />
            {this.state.specialInstructionError && (
              <div className={styles.specialInstructionError}>
                Special Characters are not allowed
              </div>
            )}
          </div>
          <Divider />
          <div className={styles.specialInstructionText}>
            <Grid
              container
              justify="center"
              alignItems="center"
              classes={{ root: styles.quantiyGrid }}
            >
              <Grid item lg={6}>
                <Chip
                  label={<Remove />}
                  className={
                    this.state.quantity > 1 && !isAddToCartDisabled
                      ? classes.quantityIcon
                      : styles.disabledQuantityIcon
                  }
                  onClick={() =>
                    this.state.quantity > 1 && !isAddToCartDisabled
                      ? this.setState({
                          quantity: --this.state.quantity,
                          errorMessage: "",
                        })
                      : null
                  }
                />
                {this.state.quantity}
                <Chip
                  label={<Add />}
                  className={
                    this.props.item.has_variation
                      ? this.state.quantity < available_qty &&
                        !isAddToCartDisabled
                        ? classes.quantityIcon
                        : styles.disabledQuantityIcon
                      : this.state.quantity < this.props.item.available_qty &&
                        !isAddToCartDisabled
                      ? classes.quantityIcon
                      : styles.disabledQuantityIcon
                  }
                  onClick={() => {
                    if (
                      (this.props.item.has_variation &&
                        this.state.quantity < available_qty &&
                        !isAddToCartDisabled) ||
                      (!this.props.item.has_variation &&
                        this.state.quantity < this.props.item.available_qty &&
                        !isAddToCartDisabled)
                    ) {
                      this.setState({ quantity: this.state.quantity + 1 });
                    } else {
                      // Display error message when increment button is disabled
                      this.setState({
                        errorMessage: `Maximum available quantity is ${available_qty} only!`,
                      });
                      // You can also set a state variable to control the visibility of the error message
                    }
                  }}
                />
              </Grid>
            </Grid>
            {/* {isAddToCartDisabled && (
              <span style={{ color: "red", paddingTop: "10px", fontSize: 15 }}>
                This variation is not available for purchase
              </span>
            )} */}
            {this.state.errorMessage && (
              <span
                style={{
                  color: "red",

                  paddingTop: "10px",
                  fontSize: 15,
                }}
              >
                {this.state.errorMessage}
              </span>
            )}
            {this.state.quantity > available_qty && (
              <span style={{ color: "red", paddingTop: "10px", fontSize: 15 }}>
                This variation has been sold out available quantity - (
                {available_qty}) only !
              </span>
            )}
          </div>
          <StickyBox offsetBottom={0} bottom style={{ zIndex: 200 }}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth={true}
              onClick={() => {
                this.addToOrder();
              }}
              className={styles.orderButton}
              disabled={isAddToCartDisabled}
            >
              {this.getOrderButtonText()}
            </Button>
          </StickyBox>
        </div>
      );
    } else {
      const { classes } = this.props;
      let itemName = this.props.historyJsonItem
        ? this.props.historyJsonItem.item.item_name
        : this.props.item.item_name;
      let itemDescription = this.props.historyJsonItem
        ? this.props.historyJsonItem.item.description
        : this.props.item.description;

      return (
        <div
          style={{
            justifyContent: "center",
            backgroundColor: "#fff",
            height: "100%",
          }}
        >
          <StickyBox className={styles.titleContainer}>
            <div className={styles.titleBox}>
              <div className="font20Bold">{itemName}</div>
              <div className="subhead">{itemDescription}</div>
            </div>
          </StickyBox>
          <Divider />
          {this.state.variationList.length > 0 && (
            <div style={{ display: "block" }}>
              <div className={`font16Bold ${styles.modifierTitle}`}>
                Choose Your Size
              </div>
              <List className={styles.listContainerStyle}>
                {this.state.variationList.map((variation, i) => (
                  <ListItem
                    key={"variation" + i}
                    button
                    onClick={() => this.setSelectedVariation(variation)}
                    className={styles.listStyle}
                  >
                    <ListItemText
                      primary={
                        variation.variation_name +
                        " ($" +
                        (+variation.variation_cost).toFixed(2) +
                        ")"
                      }
                    />
                    <Radio
                      checked={this.isSelectedVariation(variation)}
                      className="font16"
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          )}
          <div>{this.displayModifierList()}</div>
          <Divider />
          <div className={styles.specialInstructionText}>
            <TextField
              id="standard-basic"
              label="Special Instructions"
              fullWidth={true}
              className={styles.specialInstructionTextStyle}
              backgroundColor="#f2f4f5"
              value={this.state.specialInstructions}
              onChange={(event) => {
                var regex = new RegExp("^[a-zA-Z .,_ ]+$");
                if (regex.test(event.target.value) || !event.target.value) {
                  this.setState({
                    specialInstructions: event.target.value,
                    specialInstructionError: false,
                  });
                } else {
                  this.setState({
                    specialInstructions: event.target.value,
                    specialInstructionError: true,
                  });
                }
              }}
            />
            {this.state.specialInstructionError && (
              <div className={styles.specialInstructionError}>
                Special Characters are not allowed
              </div>
            )}
          </div>
          <Divider />
          <div className={styles.specialInstructionText}>
            <Grid
              container
              justify="center"
              alignItems="center"
              classes={{ root: styles.quantiyGrid }}
            >
              <Grid item lg={6}>
                <Chip
                  label={<Remove />}
                  className={
                    this.state.quantity > 1
                      ? classes.quantityIcon
                      : styles.disabledQuantityIcon
                  }
                  onClick={() =>
                    this.state.quantity > 1
                      ? this.setState({ quantity: --this.state.quantity })
                      : null
                  }
                />
                {this.state.quantity}
                <Chip
                  label={<Add />}
                  className={classes.quantityIcon}
                  onClick={() =>
                    this.setState({ quantity: ++this.state.quantity })
                  }
                />
              </Grid>
            </Grid>
          </div>
          <StickyBox offsetBottom={0} bottom style={{ zIndex: 200 }}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth={true}
              onClick={() => {
                this.addToOrder();
              }}
              className={styles.orderButton}
            >
              {this.getOrderButtonText()}
            </Button>
          </StickyBox>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    menu_items: MenuSelectors.getMenuItems(state)
      ? MenuSelectors.getMenuItems(state)
      : [],
    selectedProductsQty: OrderSelectors.getTotalAvailableQty(state),
    variationList: MenuSelectors.getVariationList(state) || [],
    groupList: MenuSelectors.getGroupList(state) || [],
    modifierList: MenuSelectors.getModifierList(state) || [],
    taxList: MenuSelectors.getTaxList(state) || [],
    selectedProducts: OrderSelectors.getProducts(state),
  };
};

export default connect(mapStateToProps)(withStyles(classes)(CustomizeItem));
